// src/components/ImageComponent.tsx
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ImageResource } from '../types/ImageResource';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface ImageComponentProps {
  image: ImageResource;
}

const ImageComponent: React.FC<ImageComponentProps> = ({ image }) => {
  return (
    <div className="relative group">
      <a href={image.downloadLink} download className="block">
        <LazyLoadImage
          effect="blur"
          src={image.src}
          alt="Image"
          className="w-full h-auto"
          threshold={100}
        />
        <div className="absolute bottom-0 left-0 w-full py-6 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
          <a
            href={image.downloadLink}
            download
            className="flex items-center text-xl font-bold justify-center text-white"
          >
            <ArrowDownTrayIcon className="h-8 w-8 mr-2" />
            Ladda ner
          </a>
        </div>
      </a>
    </div>
  );
};

export default ImageComponent;
